import React from 'react';
import Header from '@components/Header';
import { SiteContextProvider, client } from '@provider';
import { ApolloProvider } from '@apollo/client';

export const wrapPageElement = ({ element }) => (
	<ApolloProvider client={client}>
		<SiteContextProvider>
			<Header>
				{element}
			</Header>
		</SiteContextProvider>
	</ApolloProvider>
);