import { gql } from '@apollo/client';

const GET_SESSION = gql`
	query session($sessionToken: String) {
		objects {
			find_Session(
			where: { sessionToken: { _eq: $sessionToken } }
			) {
				results {
					objectId
					user {
                        id: objectId
						objectId
						username
						email
						customers {
							results {
								id: objectId
								objectId
								name
								familyname
								street
								city
								firm
								areacode
								orders {
									results {
										id: objectId
										objectId
										updatedAt
										products
										currency
										product_amount
										subtotal
									}
								}
							}
						}
                    }
				}
			}
		}
	}
`;

export default GET_SESSION;
