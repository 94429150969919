const componentStyles = {
	borderRadius: {
		small: '4px',
		medium: '8px',
		large: '12px'
	},
	siteInnerContent: {
		maxWidth: '1040px',
		marginLeft: 'auto',
		marginRight: 'auto',
		position: 'relative'
	},
	fontSize: {
		tiny: '9px',
		small: '12px',
		semismall: '14px',
		medium: '16px',
		semilarge: '20px',
		large: '24px'
	},
	padding: {
		tiny: '3px 9px',
		small: '6px 12px',
		medium: '10px 20px',
		large: '14px 28px'
	},
	containerWidth: {
		small: 'calc(33.333% - 1em)',
		medium: 'calc(50% - 1em)',
		large: 'calc(66.666% - 1em)'
	},
	siteLayout: {
		small: '33%',
		medium: '50%',
		siteLayoutLarge: '67%'
	},
	sizes: {
		small: '6px',
		medium: '12px',
		large: '18px',
		extraLarge: '42px'
	},
	shadows: {
		regular: '0 2px 2px 0 rgb(14 26 3 / 14%), 0 3px 1px -2px rgb(14 26 3 / 20%), 0 1px 5px 0 rgb(14 26 3 / 12%)',
		hover: '0 4px 4px 0 rgb(0 0 0 / 14%), 0 5px 3px -4px rgb(0 0 0 / 20%), 0 3px 7px 0 rgb(0 0 0 / 12%)'
	},
	siteContainer : {
		padding: '6px 12px',
		position: 'relative'
	}


};
  
export default componentStyles;