import { axiosclient, useDataHandler } from '@provider';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

const ContactForm = () => {
	const { createData} = useDataHandler();
	const [loading, setLoading] = useState(false);
	const [userMessage, setUserMessage] = useState(false);

	const dataHandler = async (values) => {
		setLoading(true);
		await createData({
			className: 'Forms',
			updateObject: {
				type: 'contact',
				data: values

			}
		});
		await axiosclient().post('/functions/contact_mail', values );
    
		setLoading(false);
		setUserMessage(true);
		setTimeout(() => {
			setUserMessage(false);
		}, [5000]);
	};

	return (
		<div>
			<h3 css={{margin:0}}>
				Kontaktformular
			</h3>
			<Formik
				initialValues={{
					name: '',
					message: '',
					email: ''
				}}
				onSubmit={async (values, actions) => {
					await dataHandler(values);
					actions.resetForm();
				}}
			>
				<Form>
					<label htmlFor="name">Vor- und Nachname</label>
					<Field id="name" name="name" placeholder="Vor- und Nachname" />
					<label htmlFor="email">Email</label>
					<Field
						id="email"
						name="email"
						placeholder="beispiel@email.com"
						type="email"
					/>
					<label htmlFor="lastName">Ihre Nachricht</label>
					<Field
						id="message"
						name="message"
						placeholder="Nachricht"
						as='textarea'
					/>
					
					{userMessage ? <h4>Ihre Nachricht wurde erfolgreich übermittelt</h4> : <button disabled={false} className='submit_button' type="submit">{loading ? 'Wird übermittelt' : 'Formular absenden'}</button>}
				</Form>
			</Formik>
		</div>
	);
};

export default ContactForm;