import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SiteContext from './SiteContext';
import { GET_ORDER } from '../apollo';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import useGetProducts from './hooks/useGetProducts';
import { useDataHandler } from '../data';
import getProductAmount from './functions/getProductAmount';
import getOrderSubtotal from './functions/getOrderSubtotal';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import GET_SESSION from './constants/GET_SESSION';
import LOGIN_USER from './constants/LOGIN_USER';
import { navigate } from 'gatsby';

const SiteContextProvider = ({children}) => {
	const [order, setOrder] = useState();
	const [user, setUser] = useState();
	const [contactOpen, setContactOpen] = useState(false);
	const products = useGetProducts();
	const {updateData, createData} = useDataHandler();
	const client = useApolloClient();

	const [getSession, {loading: sessionLoading}] = useLazyQuery(GET_SESSION, {
		fetchPolicy: 'no-cache'
	});

	const sessionHandler = (token) => {
		getSession({variables: { sessionToken: token }}).then(results => {
			setUser(results.data.objects.find_Session.results[0].user);
		});
	};

	useEffect(() => {
		const token = Cookies.get('st_token');
		if (token && !user) {
			sessionHandler(token);
		}
	}, []);

	const getOrderHandler = useCallback(async id => {
		const {data} = await client.query({
			query: GET_ORDER,
			fetchPolicy: 'no-cache',
			variables: {id}
		});
		if (!data) {
			Cookies.remove('sd_order');
		}

		if (data) {
			setOrder(data.objects.getOrders);
			if (Cookies.get('sd_order')) {
				if (Cookies.get('sd_order') !== data.objects.getOrders.objectId) {
					Cookies.set('sd_order', data.objects.getOrders.objectId, {expires: 30} );
				}
			} else {
				Cookies.set('sd_order',data.objects.getOrders.objectId, {expires: 30} );
			}
		}
	}, [client]);

	const afterLoginHandler = useCallback(async (user) => {
		Cookies.set('sd_session', user.sessionToken, { expires: 30});
		await getSession({variables: { sessionToken: user.sessionToken }}).then(results => {
			setUser(results.data.objects.find_Session.results[0].user);
			setTimeout(() => {
				navigate('/app/profile');
			}, [300]);
		});
	}, []);

	const [userLogin, {loading}] = useMutation(LOGIN_USER,
		{
			fetchPolicy: 'no-cache',
			onError: loginError => { 
				if (loginError.message === 'Invalid username/password.') {
					window.alert('Falsche E-Mail / Passwort Kombination');
				}
			},
			onCompleted: data => afterLoginHandler(data.users.logIn)
		}) ;

	const changeProductAmount = useCallback((productId, value) => {
		const orderCopy = {...order};
		const productIndex = orderCopy.products.findIndex(product => product.product_id === productId);
		
		const newAmount = value;
		const newSubtotal = value * orderCopy.products[productIndex].price;

		orderCopy.products[productIndex] = {
			...orderCopy.products[productIndex],
			amount: newAmount,
			subtotal: newSubtotal

		};
		orderCopy.product_amount = getProductAmount(orderCopy.products);
		orderCopy.subtotal = getOrderSubtotal(orderCopy.products);

		updateData({
			className: 'Orders',
			objectId: orderCopy.objectId,
			updateObject: {
				product_amount: orderCopy.product_amount	,
				subtotal: orderCopy.subtotal,
				products: orderCopy.products
			}
		});

		setOrder(orderCopy);
	});

	useEffect(() => {
		const cartCookie = Cookies.get('sd_order');

		if (cartCookie) {
			getOrderHandler(cartCookie);
		}
	}, []);

	const addToCart = async product => {
		const productObject = {
			product_id: product.objectId,
			amount: 1,
			price: product.price,
			subtotal: 1 * product.price
		};
		
		if (order) {
			const orderCopy = {...order};
	
			orderCopy.products.push(productObject);

			updateData({
				className:'Orders',
				objectId: orderCopy.objectId,
				updateObject: {
					products: orderCopy.products, 
					product_amount: getProductAmount(orderCopy.products),
					subtotal: getOrderSubtotal(orderCopy.products),
					state: 'Warenkorb'
				}
			});
		}

		if (!order) {
			const orderObject = await createData({
				className: 'Orders',
				updateObject: {
					products: [productObject], 
					product_amount: getProductAmount([productObject]),
					subtotal: getOrderSubtotal([productObject]),
					state: 'Warenkorb'
				}
			});

			getOrderHandler(orderObject.data.objectId);
		}
	};

	const productListItems = useMemo(() => {
		const productArray = [];
		order?.products?.forEach(product => {
			const productToList = products.find(pr => pr.objectId === product.product_id );
			productArray.push({...productToList, amount: product.amount, subtotal: product.subtotal });
		} );

		return productArray;
	}, [products, order]);


	const siteContext = useMemo(() => ({
		order,
		products,
		orderProducts: productListItems,
		addToCart,
		changeProductAmount,
		sessionHandler,
		user,
		login: userLogin,
		loading: loading || sessionLoading,
		contactOpen,
		setContactOpen: contactState => {
			setContactOpen(contactState);
		}

	}), [order, productListItems, user, contactOpen]);

	return (
		<SiteContext.Provider
			value={siteContext}
		>
			{children}
		</SiteContext.Provider>
	);
};

SiteContextProvider.propTypes ={
	children: PropTypes.node.isRequired
};

export default SiteContextProvider;