export const slideInAnimation = {
	initial: {
		x: '102%'
	},
	animate: {
		x: '0%',
		transition: {
			ease: 'easeOut'
		}
	}
	
};