import { graphql, useStaticQuery } from 'gatsby';

const useSiteContent = (type) => {
	const data = useStaticQuery(graphql`
        query HomeContentQuery {
            shd {
                objects {
                    findContent {
                        results {
                            objectId
                            type
                            content
                        }
                    }
                }
            }
        }
    `);

	const getContent = (signifier ) => {
		let returnValue = '';
		const contentElement =  data.shd.objects.findContent.results.find(content => content.type === type);
        
		contentElement?.content?.forEach(element => {
			if (element.signifier === signifier ) {
				returnValue = element.text;
			}
		});

		return returnValue;
	};


	return ({getContent});
};

export default useSiteContent;