const colors = {
	primary: '#d75055',
	primaryShaded: '#b3d226',
	secondary: '#005756',
	secondaryShaded: '#063a49',
	secondaryLight: '#cedbda',
	tertiary: '#91aec1',
	tertiaryShaded: '#360e41',
	quatiary: '#00D9C0',
	quatiaryShaded: '#00ae9a',

	category_1: '#91aec1',
	category_2: '#005756',
	category_3: '#E82042',
	category_4: '#CEA9FF',

	dark: '#003433',
	light: '#f7f7f7',
	white: '#ffffff',

	grey: '#a6a6a6', // oder #bcbcbc
	lightgrey: '#f3f3f4',
	darkgrey: '#373737'
};

export default colors;

