export const variants = {
    initial: {opacity: 0, y:-80, transition: { duration: 0.4, opacity: {delay: 0.5}}},
    animate: {opacity: 1, y: 0, transition: {ease: "easeOut", delay: 0.4 }}
  }
  export const menuToggler1 ={
    open: { x:0, },
    closed: { x: -5 }
  }
  export const menuToggler2 ={
    open: { x:0},
    closed: { x:5}
  }
  export const menuToggler3 = {
    open: {x:0 },
    closed: {x: -5 }
  }
  
  export const navlist = {
    initial: {
      transition: { staggerChildren: 0.05, delayChildren: 0.2 }
    },
    visible: {
      transition: { staggerChildren: 0.05, delayChildren: 0.15 }
    },
    hidden: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };
  export const navitem = {
    initial: {
      y: 40,
      opacity: 0,
      transition: {
        ease: "easeOut"
      }
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeOut"
      }
    },
    hidden: {
      y: 40,
      opacity: 0,
      transition: {
        ease: "easeOut"
      }
    }
  };

  export const menuenterleft = {
    initial: { 
        x: "-100%",
        transition: {
            x: {duration: 0.4, delay: 0, ease: "easeIn"},
        }
     },
    visible: { 
        x: 0,
        transition: {
            x: {duration: 0.4, delay: 0, ease: "easeOut"},
        }
     },
    hidden: { 
        x: "-110%", 
        transition: {
            x: {duration: 0.4, delay: 0.1, ease: "easeOut"} ,
        }
  }
};