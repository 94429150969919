import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { productsContainer } from '../styles';
import ExtendedButton from '@ui/ExtendedButton';
import { SiteContext, currencyFormatter } from '@provider';
import ProductListElement from './ProductListElement';
import FlexBox from '@ui/FlexBox';

const ProductList = ({productLinkHandler}) => {
	const {orderProducts, order, changeProductAmount} = useContext(SiteContext);

	return (
		<div css={productsContainer}>
			{order && orderProducts.length > 0 ? 
				<>
					{orderProducts.map(product => <ProductListElement key={product.objectId} product={product} changeAmount={changeProductAmount} /> )}
					<FlexBox align='center' justify='space-between' hasFullWidth>
						<p>
							Gesamt
						</p>
						<p>
							{currencyFormatter.format(order.subtotal)}
						</p>
					</FlexBox>
				</>
				:
				<>
					<h4>
						Keine Produkte im Warenkorb
					</h4>
					<ExtendedButton text='Zu den Produkten' onClick={() => productLinkHandler('/produkte')} />
				</>
			}
			
		</div>
	);
};

ProductList.propTypes ={
	productLinkHandler: PropTypes.func.isRequired
};

ProductList.defaultProps = {
	products: []
};

export default ProductList;