import Divider from '@ui/Divider';
import FlexBox from '@ui/FlexBox';
import React from 'react';
import PropTypes from 'prop-types';
import { Minus, Plus } from 'lucide-react';
import settings from '@settings';
import { clickIcon, productListElementContainer } from '../styles';
import { currencyFormatter } from '@provider';

const ProductListElement = ({product, changeAmount}) => {
	return (
		<FlexBox
			direction='column'
			align='stretch'
			hasFullWidth
			styles={productListElementContainer}
		>
			<FlexBox direction='row' align='center' justify='space-between'>
				{product.title}
				<FlexBox
					direction='row'
					align='center'
					justify='space-between'
					gap={settings.sizes.small}
				>
					<button 
						css={[clickIcon, {cursor: product.amount === 1 ? 'regular' : 'pointer' }]} 
						onClick={() => changeAmount(product.objectId, product.amount - 1)}
						disabled={product.amount === 1}
					>
						<Minus size={16} strokeWidth={1.5} color={product.amount === 1 ? settings.colors.grey : settings.colors.dark} />
					</button>
					{ product?.amount?.toString()}
					<button css={clickIcon} onClick={() => changeAmount(product.objectId, product.amount + 1)}>
						<Plus size={16} color={settings.colors.dark} strokeWidth={1.5} />
					</button>
				</FlexBox>
			</FlexBox>
			<Divider />
			<FlexBox direction='row' align='center' justify='space-between'>
				<p css={{fontWeight: 500, margin:0}}>Summe </p>
				<div>{currencyFormatter.format(product.subtotal)} </div>
			</FlexBox>
		</FlexBox>
	);
};

ProductListElement.propTypes = {
	product: PropTypes.object.isRequired,
	changeAmount: PropTypes.func.isRequired
};

export default ProductListElement;