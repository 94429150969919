import settings from '@settings';

export const contactIndicator = {
	position: 'absolute',
	left: '0',
	transform: 'translate(-100%,0)',
	bottom: '60px',
	border: `1px solid ${settings.colors.grey}`,
	borderRadius: `${settings.sizes.large} 0 0 ${settings.sizes.large}`,
	padding: settings.padding.medium,
	backgroundColor: settings.colors.light,
	cursor: 'pointer',
	color: settings.colors.dark,
	zIndex: 3,
	transition: 'all .4s ease',
	boxShadow: '-3px 0px 12px 2px rgba(0,0,0,0.15)' 
};

export const cartIndicator = {
	position: 'fixed',
	right: '24px',
	top: '12px',
	fontSize: '18px',
	border: `.8px solid ${settings.colors.secondary}`,
	borderRadius: '50%',
	width: '36px',
	height: '36px',
	display: 'flex',
	justifyContent: 'center',
	alignItems:'center',
	backgroundColor: settings.colors.secondary,
	cursor: 'pointer',
	color: settings.colors.light,
	zIndex: 6
};

export const cartSlideIn = {
	zIndex: 15,
	position:'fixed', 
	top: '0%', 
	right: '0%', 
	height: '100%',
	backgroundColor: settings.colors.white, 
	boxShadow: '-3px 0px 12px 2px rgba(0,0,0,0.15)', 
	display: 'flex', 
	flexDirection: 'column',
	justifyContent: 'space-between', 
	overflow: 'visible',
	borderLeft: `1px solid ${settings.colors.grey}` ,
	'h3': {
		textAlign: 'center'
	}
	// 'label': {marginTop: '2.4rem', marginBottom: '0.6rem'}
};

export const overlay = {
	zIndex: 10,
	top: 0,
	left: 0,
	position:'fixed', 
	width: '100vw', 
	height: '100vh', 
	backgroundColor: 'rgba(0,0,0,0.1)'
};

export const contentContainer = settings.mq({
	display: 'flex',
	flexDirection: ['column'],
	gap: settings.sizes.medium,
	padding: settings.padding.medium,
	overflow: 'auto'
});

export const iconsContainer = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: settings.sizes.medium,
	color: settings.colors.darkgrey,
	'a': {
		fontFamily: 'Poppins',
		fontSize: settings.fontSize.medium,
		fontWeight: 300,
		color: settings.colors.darkgrey,
		textDecoration: 'underline'

	}
};

export const productsContainer = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'flex-start',
	height: '100%',
	padding: settings.padding.medium
};

export const displayProductNumber = {
	fontFamily:'Poppins',
	position: 'absolute',
	top: -6,
	left: -6,
	backgroundColor: settings.colors.primary,
	borderRadius: '50%',
	color: settings.colors.white,
	width: '18px',
	height: '18px',
	display: 'flex',
	alignItems: 'center',
	justifyContent:'center',
	fontSize: '12px'
};

export const clickIcon ={
	cursor: 'pointer'
};

export const productListElementContainer = {
	padding: settings.padding.small,
	backgroundColor: settings.colors.light
};