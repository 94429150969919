import { gql } from '@apollo/client';

const LOGIN_USER = gql` 
	mutation logIn($password: String!, $username: String!) {
		users {
			logIn(username: $username, password: $password){
				username
				email
				sessionToken
			}
		}
	}
`;

export default LOGIN_USER;