import PropTypes from 'prop-types';

import React from 'react';
import styled from '@emotion/styled';
import settings from '@settings';

const FlexBox = ({children, direction, align, justify, gap, wrap, changeToColumn, styles, hasFullWidth}) => {
	const StyledFlexBox = styled.div(settings.mq({
		height: 'auto',
		padding: 0,
		display: 'flex',
		overflow: 'visible',
		position: 'relative',
		minWidth: 0,
		justifyContent: justify,
		alignItems: changeToColumn ? ['flex-start', 'flex-start', align] : align,
		flexDirection: changeToColumn ? ['column', 'column', 'row', 'row'] : direction,
		width: hasFullWidth ? '100%' : 'auto',
		gap,
		flexWrap: wrap ? 'wrap' : 'no-wrap',
		...styles
	}));

	return (
		<StyledFlexBox>
			{children}
		</StyledFlexBox>
	);

};

FlexBox.propTypes = {
	children: PropTypes.node,
	direction: PropTypes.string,
	align: PropTypes.string,
	justify: PropTypes.string,
	gap: PropTypes.string,
	wrap: PropTypes.bool,
	changeToColumn: PropTypes.bool,
	hasFullWidth: PropTypes.bool,
	styles: PropTypes.object
};

FlexBox.defaultProps = {
	children: '',
	direction: 'row',
	align: 'flex-start',
	justify: 'flex-start',
	gap: '0',
	wrap: false,
	changeToColumn: false,
	hasFullWidth: false,
	styles: {}
};

export default FlexBox;