import settings from '@settings';

export const navmenu = settings.mq({
	position: 'fixed', 
	height: ['100vh', '100vh', '100vh', '100vh'],
	width: ['85%','70%', '55%','40%'],
	left: 0,
	backgroundColor: settings.colors.white,
	top: 0,
	right: 0,
	bottom: 0,
	zIndex: 998,
	overflowX: 'hidden',
	overflowY: ['auto','auto', 'auto', 'hidden'],
	boxShadow: '-3px 0px 12px 2px ' + settings.colors.darkgrey, 
	fontSize: '16px',
	paddingLeft: '60px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	justifyContent: 'space-between'
});

export const navlink = settings.mq({
	fontSize: ['0.7rem', '0.8rem', '0.9rem'],
	fontWeight: 400,
	color: settings.colors.darkgrey,
	marginBottom: '0.4em',
	transition: 'all 0.2s ease-out',
	textDecoration: 'underline',
	[':hover']: {color: settings.colors.primary}
});

export const overlay = {
	position: 'fixed',
	width: '100vw', 
	height: '100vh', 
	overflow: 'hidden', 
	zIndex: 3,
	backgroundColor: settings.colors.grey
};

export const navborderitems = {
	initial: {opacity: 0, transition: {delay: 0, duration: 0.1}},
	animate: {opacity: 1, transition: {delay: 0.2, duration: 0.6}},
	exit: {opacity: 0, transition: {delay: 0, duration: 0.6}}
};
  
export const navbackground = {
	initial: {backgroundColor: 'rgba(0,0,0,0)', transition: {delay: 0, duration: 0.4}},
	animate: {backgroundColor: 'rgba(0,0,0,0.3)', transition: {delay: 0, duration: 0.6}},
	exit: {backgroundColor: 'rgba(0,0,0,0)', transition: {delay: 0, duration: 0.6}}
};

export const linkstyle = settings.mq({
	color: 'inherit',
	textDecoration: 'none',
	fontSize: ['1.2rem', '1.2rem','1.4rem'],
	textTransform: 'uppercase',
	lineHeight: ['1.2rem', '1.2rem','1.8rem'],
	transition: 'all 0.4s ease-out',
	fontWeight: 400,
	letterSpacing: '0.5px',
	fontFamily: 'Poppins',
	position: 'relative',
	[':hover']: {color: settings.colors.dark}
});

export const sublinkstyle = settings.mq({
	color: 'inherit',
	marginTop:['0.1rem', '0.2rem', '0.3rem'],
	textDecoration: 'none',
	fontSize: ['0.7rem', '0.7rem','0.9rem'],
	lineHeight: '0.9rem',
	transition: 'all 0.4s ease-out',
	fontWeight: 500,
	letterSpacing: '0.5px',
	fontFamily: 'Poppins',
	textTransform: 'none'
});

export const liststyle = settings.mq({
	width: '100%',
	listStyle: 'none',
	overflowY: ['hidden','hidden', 'hidden'],
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	justifyContent: 'flex-start',
	padding: 0,
	gap: '0.9rem'
});

export const listitem = settings.mq({
	width: ['90%', '90%', '90%'],
	float: ['none', 'none', 'left'],
	margin: ['0.0.3rem 0', '0.0.3rem 0', '0.3rem 0'],
	padding: 0
});

export const underline = {
	backgroundColor: settings.colors.dark,
	height: '2px',
	position: 'absolute',
	left: 0,
	bottom: 0,
	zIndex: 2
};

export const underlineGrey = {
	backgroundColor: 'transparent',
	height: '2px',
	position: 'absolute',
	left: 0,
	bottom: 0,
	width: '100%'
};

export const externalLinkStyle = {
	textDecoration: 'none',
	color: 'inherit',
	display: 'flex',
	alignItems: 'center',
	gap: settings.sizes.medium
};