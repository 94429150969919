import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fonts, headerContainer, logoContainer } from './styles';
import { StaticImage } from 'gatsby-plugin-image';
import MenuIcon from './components/MenuIcon';
import Navigation from './content/Navigation';
import Contact from './content/Contact';
import Cart from './content/Cart';
import UserDisplay from './content/UserDisplay';

const Header = ({children}) => {
	const [showMenu, setShowMenu] = useState(false);
	
	return (
		<>
			<div css={[headerContainer, fonts]}>
				<MenuIcon showMenu={showMenu} setShowMenu={setShowMenu} />
				<div type='button' css={logoContainer} onClick={() => setShowMenu(!showMenu)}>
					<StaticImage
						alt='Saaman Solution'
						src="./images/logo_diagnostik_2_1.png"
						width={150}
						style={{top: -5}}
						layout="fixed"
					/>
				</div>
			</div>
			<Navigation showMenu={showMenu} setShowMenu={setShowMenu} />
			<Contact showMenu={showMenu} setShowMenu={setShowMenu} />
			<UserDisplay />
			{typeof window !== 'undefined' && <Cart showMenu={showMenu} setShowMenu={setShowMenu} />}
			{children}
		</>
	);
};

Header.propTypes = {
	children: PropTypes.node.isRequired
};

export default Header;