import currencyFormatter from './currencyFormatter';

const getTaxAmount = (amount, tax) =>{
	const taxValue = tax || 19;

	console.log(taxValue);
	const taxFactor = 1 + (taxValue / 100); 
	console.log(taxFactor);
	console.log(amount / taxFactor);
	return ({ 
		taxAmount: currencyFormatter.format( amount ? amount - (amount / taxFactor) : 0),
		noTaxAmount: currencyFormatter.format( amount ? (amount / taxFactor) : 0)
	});

};
    
export default getTaxAmount;