import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import {css} from '@emotion/react';
import {menuenterleft, navitem, navlist} from './animations';
import { externalLinkStyle, listitem, liststyle, navbackground, navborderitems, navlink, navmenu, overlay } from './styles';
import settings from '@settings';
import LinkElement from './components/LinkElement';
import { StaticImage } from 'gatsby-plugin-image';
import { PiArrowSquareOutThin } from 'react-icons/pi';

const Navigation = ({showMenu, setShowMenu}) => {
  
	return (
		<AnimatePresence mode='sync'> 
			{showMenu && 
				<>
					<motion.div
						key="navbackground"
						variants={navbackground}
						initial="initial"
						animate="animate"
						exit="exit"
						css={overlay}
						onClick={() => setShowMenu(false)}
					/>
					<motion.div 
						key="345" 
						css={navmenu} 
						initial="initial"
						animate="visible"
						exit="hidden"
						variants={menuenterleft}
					>
						<div css={{paddingTop: settings.sizes.large}}>
							<StaticImage 
								layout='constrained'
								src='./images/logo_diagnostik_2.png'
								alt='Saaman Diagnostik'
								width={120}
							/>
						</div>
						<motion.div
							css={liststyle}
							variants={navlist}
							initial="initial"
							animate="visible"
							exit="hidden"
						> 
							<motion.li variants={navitem} css={listitem} key="23244">
								<LinkElement name={'start'} setShowMenu={setShowMenu} link={'/'} />
							</motion.li>
							{/* <motion.li variants={navitem} css={listitem} key="45478">
								<LinkElement name={'Produkte'} setShowMenu={setShowMenu} link={'/produkte'} />
							</motion.li> */}
							<motion.li variants={navitem} css={listitem} key="46738">
								<LinkElement name={'Diagnostik'} setShowMenu={setShowMenu} link='/diagnostik' />
							</motion.li>
							<motion.li variants={navitem} css={listitem} key="42378">
								<LinkElement name={'Saaman'} setShowMenu={setShowMenu} link='/about' />
							</motion.li>
							<motion.li variants={navitem} css={listitem} key="42238">
								<a 
									css={externalLinkStyle}
									href={'https://app.saaman-diagnostik.de'}
								>
									<LinkElement name={'Zum Testportal'} setShowMenu={setShowMenu} link='' />
									<PiArrowSquareOutThin size={24}  strokeWidth={2} />
								</a>
							</motion.li>
						</motion.div>
						<motion.div
							variants={navborderitems}
							initial="initial"
							animate="animate"
							exit="exit"
							key="48768"
							css={css`height: auto; width: 100%; border-top: 1px solid ${settings.colors.dark}; padding-top: 0.6rem; margin-top: 1.2rem;`}
						>
							<div css={css`width: 100%; height: 100%; display: flex; flex-direction: row; justify-content: flex-start; gap: 1.2rem;`}>
								<Link
									css={navlink}
									onClick={() => setShowMenu(false)}
									to="/impressum"
									activeStyle={{color: settings.colors.primary}}
								> Impressum</Link>
								<Link
									css={navlink}
									onClick={() => setShowMenu(false)}
									to="/datenschutz"
									activeStyle={{color: settings.colors.primary, borderBottom: '1px solid ' + settings.colors.primary}}
								> Datenschutz</Link>
								<Link
									css={navlink}
									onClick={() => setShowMenu(false)}
									to="/agb"
									activeStyle={{color: settings.colors.primary, borderBottom: '1px solid ' + settings.colors.primary}}
								> AGB</Link>
							</div>
						</motion.div>
					</motion.div>
				</>
			}
		</AnimatePresence>
	);
};

Navigation.propTypes = {
	showMenu: PropTypes.bool.isRequired, 
	setShowMenu: PropTypes.func.isRequired
}; 

export default Navigation;