import React, { useState } from 'react';
import { linkstyle, underline, underlineGrey } from '../styles';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import settings from '@settings';

const LinkElement = ({name, setShowMenu, link}) => {
	const [hover, setHover] = useState(false);

	return link ? 
		<Link
			onClick={() => setShowMenu(false)}
			to={link}
			css={linkstyle}
			activeStyle={{color: settings.colors.primary, borderBottom: 'none'}}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			{name}
			<motion.div css={underline} animate={{width: hover ? '100%' : '0'}} transition={{duration: .2, ease: 'easeOut'}} />
			<motion.div css={underlineGrey} transition={{duration: .2, ease: 'easeOut'}} />
		</Link>
		:
		<div
			onClick={() => setShowMenu(false)}
			to={link}
			css={linkstyle}
			// activeStyle={{color: settings.colors.primary, borderBottom: "2px solid " + settings.colors.primary}}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			{name}
			<motion.div css={underline} animate={{width: hover ? '100%' : '0'}} transition={{duration: .2, ease: 'easeOut'}} />
			<motion.div css={underlineGrey} transition={{duration: .2, ease: 'easeOut'}} />
		</div>;
};

LinkElement.propTypes = {
	name: PropTypes.string.isRequired,
	setShowMenu: PropTypes.func.isRequired,
	link: PropTypes.string
};

LinkElement.defaultProps = {
	link: undefined
};

export default LinkElement;