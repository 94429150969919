import { ApolloClient, InMemoryCache} from '@apollo/client';
import fetch from 'isomorphic-fetch';

const client = new ApolloClient({
	uri: 'https://pg-app-fap0oj0r2waejk7ibh713txcsxw0yd.scalabl.cloud/graphql/',
	fetchOptions: {
		mode: 'no-cors'
	},
	headers: {
		'X-Parse-Application-Id': 'MZeNY1QH6EHZ3AC3L4E58bsOgBMnp9SVUF9Onm4X',
		'X-Parse-Master-Key': 'cU1cLsZm8QE9kMc9Phhtu0nMFN4pXBWOgP9SVmPV'
	},
	connectToDevTools: true,
	cache: new InMemoryCache(),
	fetch

});


export default client;
 