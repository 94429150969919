import { graphql, useStaticQuery } from 'gatsby';

const useGetProducts = () => {
	const data = useStaticQuery(graphql`
        query allProducts {
            shd {
                objects {
                    find_Product(where: {published: {_eq: true}}) {
                        results {
                            id: objectId
                            objectId
                            title
                            subtitle
                            order
                            description
                            short_description
                            price
                            categories
                            questions
                            icon {
                                name
                                url
                            }
                            published
                            test {
                                objectId
                                name
                            }
                        }
                    }
                }
            }
        }
    `);

	return data.shd.objects.find_Product.results;
 
};

export default useGetProducts;