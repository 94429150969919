import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {AnimatePresence, motion} from 'framer-motion';
import { cartIndicator, cartSlideIn, displayProductNumber, overlay } from './styles';
import { slideInAnimation } from './animations';
import settings from '@settings';
import ProductList from './components/ProductList';
import { ShoppingCartIcon } from 'lucide-react';
import Divider from '@ui/Divider';
import FlexBox from '@ui/FlexBox';
import { navigate } from 'gatsby';
import { SiteContext } from '@provider';

const ShoppingCart = ({showMenu, setShowMenu}) => {
	const [showCart, setShowCart] = useState(false);
	const {order} = useContext(SiteContext);

	const [showCartIcon, setShowCartIcon] = useState(true);
	useEffect(() => {
		if (window) {
			console.log(window.location.pathname);
			if (window.location.pathname === '/checkout/') {
				setShowCartIcon(false);
			}
			if (window.location.pathname !== '/checkout/') {
				setShowCartIcon(true);
				
			}
		}
	}, [window.location.pathname]);

	const contactOpenHandler = () => {
		if (!showMenu) {
			setShowCart(!showCart);
		}
		if (showMenu) {
			setShowMenu(false);
			setTimeout(() => {
				setShowCart(!showCart);
			}, [700]);
		}
	};

	const productLinkHandler = route => {
		navigate(route);
		setTimeout(() => {
			setShowCart(false);
		}, [500]);
	};

	return (
		<>
			<AnimatePresence>
				{showCart && 
					<motion.div 
						css={overlay} 
						key="slideIn-overlay"
						transition={{duration: .3}}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						onClick={() => setShowCart(false)}
					/>
				}
			</AnimatePresence>
			<motion.div 
				key="slidein-animation"
				custom={{duration: .3}}
				initial={slideInAnimation.initial}
				animate={showCart ? slideInAnimation.animate : slideInAnimation.initial}
				css={settings.mq([cartSlideIn, {width: ['95vw', '95vw','50vw', '33vw']}])}
				transition={{ease: 'easeOut'}}
			>
				<div>
					<h3>
						Einkaufswagen
					</h3>
					<Divider />
					<ProductList productLinkHandler={productLinkHandler} />
				</div>
				{order?.products?.length > 0 &&
					<div>
						<Divider />
						<FlexBox align='center' justify='center' hasFullWidth>
							<button className='checkout_button' onClick={() => productLinkHandler('/checkout')}>Zum Checkout </button>
						</FlexBox>
					</div>
				}
			</motion.div>
			{showCartIcon &&
				<div css={cartIndicator} onClick={()=> contactOpenHandler()}>
					{order?.products?.length > 0 && <div css={displayProductNumber} > {order?.product_amount?.toString()} </div>}
					<ShoppingCartIcon size={16} strokeWidth={1.75} color={settings.colors.light} /> 
				</div>
			}
		</>
	);
};

ShoppingCart.propTypes = {
	showMenu: PropTypes.bool.isRequired,
	setShowMenu: PropTypes.func.isRequired
};

export default ShoppingCart;