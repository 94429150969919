/* eslint-disable no-undef */
import axios from 'axios';
import Cookies from 'js-cookie';

console.log(process.env.GATSBY_SASHIDO_API_URL);

const axiosclient = () => {
	const localToken = Cookies.get('st_token');
	const token = localToken || '' ;
	return axios.create({
		baseURL: 'https://pg-app-fap0oj0r2waejk7ibh713txcsxw0yd.scalabl.cloud/1/',
		headers: {
			'X-Parse-Application-Id':  'MZeNY1QH6EHZ3AC3L4E58bsOgBMnp9SVUF9Onm4X',
			'X-Parse-REST-API-Key': 'AmHkGxWcZLEKIBYOAkPJQVWXN49VmFBgSOSFTpWB',
			'X-Parse-Master-Key': 'cU1cLsZm8QE9kMc9Phhtu0nMFN4pXBWOgP9SVmPV',
			'X-Parse-Session-Token': token
		}
	});
};

export default axiosclient;
