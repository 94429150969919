import { gql } from '@apollo/client';


const GET_ORDER = gql `
    query getOrder($id: ID!) {
        objects {
            getOrders(objectId: $id) {
                id: objectId
                objectId
                products
                currency
                product_amount
                subtotal
            }
        }
    }
`;

export default GET_ORDER;