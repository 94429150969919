import {colors, componentStyles, mq} from './definitions';

const settings = {
	colors,
	mq,
	padding: componentStyles.padding,
	fontSize: componentStyles.fontSize,
	borderRadius: componentStyles.borderRadius,
	sizes: componentStyles.sizes,
	shadows: componentStyles.shadows,
	siteInnerContent: componentStyles.siteInnerContent,
	siteContainer: componentStyles.siteContainer

};

export default settings;